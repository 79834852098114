<template>
    <div>
       <v-container fluid class="pa-0 ma-0">
          <v-toolbar style="background-color: #f6f5f5" flat>
                <v-tooltip bottom color="black" nudge-top="7">
                    <template v-slot:activator="{ on }">
                    <v-btn
                        dark 
                        fab
                        x-small
                        rounded
                        v-on="on"
                        class="mr-2"
                        color="#1565c0"
                        @click="closePage()"
                    >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    </template>
                    <span>Back</span>
                </v-tooltip>
        
                <v-toolbar-title>
                  <center>
                     <h4 style="font-family:Arial" class="mt-2"> {{ $route.params.id}}.png </h4>
                  </center>
                </v-toolbar-title>
            </v-toolbar>

            <v-card  flat>
                 <v-card-text height="100%">
                   <center>
                    <v-img
                    :src="`https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/${$route.params.id}.png`"
                    lazy-src="https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/no-image-available.png"
                    max-width="550"
                    max-height="550"
                    class="mt-10"
                    style="border: 1px solid grey;"
                    >
                    <template v-slot:placeholder>
                        <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                        </v-row>
                    </template>
                    </v-img>
                   </center>
                 </v-card-text>
            </v-card>
       </v-container>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    created(){

    },
    methods: {  
        closePage(){
            window.close()
        }
    }
}
</script>